@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.tag-list span.field-title > span {
  color: #707070;
  position: relative;
  padding-left: 15px;
  margin-right: 10px;
  border-bottom: 1px solid transparent;
}

.tag-list span.field-title > span:hover {
  cursor: pointer;
  color: #878787;
  border-bottom: 1px solid #15909c;
  text-decoration: none;
}

.tag-list span.field-title > span:before {
  content: "";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.tag-list span.field-title > a {
  color: #707070;
  background: #89C6CC;
  border-radius: 3px 0 0 3px;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 15px 0 23px;
  position: relative;
  margin: 0 20px 10px 0;
  text-decoration: none;
  -webkit-transition: color .2s;
  color: #fff;
}

.tag-list span.field-title > a:hover {
  background-color: #15909c;
}

.tag-list span.field-title > a:hover:after {
  border-left: 10px solid #15909c;
}

.tag-list span.field-title > a:before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag-list span.field-title > a:after {
  background: transparent;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #89C6CC;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
}
